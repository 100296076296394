<template>
  <section class="dashboard">
    <div class="dashboard__inner">
      <div class="dashboard__head">
        <div class="dashboard__title">
          Игровая неделя<br />{{ currentWeek || "-" }}/<span>36</span>
        </div>
      </div>
      <div class="dashboard__foot">
        <div class="dashboard__text">
          Сейчас игра идет<br />на планете {{ currentIsland.title }}
        </div>
        <div class="dashboard__pic">
          <div
            class="dashboard-island"
            :class="`dashboard-island_${currentIsland.name}`"
          ></div>
        </div>
        <div v-if="view === 'ratingMyTeam'" class="dashboard-result">
          <div class="dashboard-result__title">
            Сумма заработанных GPB-coins
          </div>
          <div class="dashboard-result__body">
            <img
              src="@/assets/img/pic2/coin.svg"
              width="26"
              height="26"
              alt=""
            />{{ getTeam.teamCoins | priceFilter }}
          </div>
        </div>
        <div v-else class="dashboard-result hidden">
          <div class="dashboard-result__title">
            Сумма заработанных GPB-coins
          </div>
          <div class="dashboard-result__body">
            <img
              src="@/assets/img/pic2/coin.svg"
              width="26"
              height="26"
              alt=""
            />0
          </div>
        </div>
      </div>
      <!-- <div v-if="view === 'rating'" class="dashboard__item">
        <div v-if="getGame.currentWeek >= 0" class="dashboard-text">
          <p>Игровая неделя: {{ currentWeek }} из 36</p>
          <div v-if="currentIsland && currentIsland.name">
            <p>
              <b>Планета {{ currentIsland.title }}</b>
            </p>
            <div
              class="dashboard-island"
              :class="`dashboard-island_${currentIsland.name}`"
            ></div>
          </div>
        </div>
        <div
          v-if="getGame.overallCoins !== 'undefined'"
          class="dashboard-control"
        >
          <p>Общее количество полученных {{ COINS }}</p>
          <div class="score">
            <span>{{ getGame.overallCoins | priceFilter }}</span>
            <span class="coin">
              <img
                src="@/assets/img/pic2/coin.svg"
                width="13"
                height="13"
                alt=""
              />
            </span>
          </div>
        </div>
        <div
          v-if="getGame.approvedGratitudes !== 'undefined'"
          class="dashboard-control"
        >
          <p>Кол-во отправленных {{ COINS }} благодарностей</p>
          <div class="score">
            <span>{{ getGame.approvedGratitudes | priceFilter }}</span>
            <span class="coin">
              <img
                src="@/assets/img/pic2/coin.svg"
                width="13"
                height="13"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>
      <div v-else-if="view === 'ratingTeam'" class="dashboard__item">
        <div v-if="currentIsland.name" class="dashboard-text">
          <p>Текущее местоположение</p>
          <p>
            <b>Планета {{ currentIsland.title }}</b>
          </p>
          <div
            class="dashboard-island"
            :class="`dashboard-island_${currentIsland.name}`"
          ></div>
        </div>
        <div
          v-if="getTeam.teamCoins !== 'undefinded'"
          class="dashboard-control"
        >
          <p>Количество полученных {{ COINS }} команды</p>
          <div class="score">
            <span>{{ getTeam.teamCoins | priceFilter }}</span>
            <span class="coin">
              <img
                src="@/assets/img/pic2/coin.svg"
                width="13"
                height="13"
                alt=""
              />
            </span>
          </div>
        </div>
        <div
          v-if="getTeam.teamCaptain && getTeam.teamCaptain.playerName"
          class="dashboard-control"
        >
          <p>Капитан команды</p>
          <p>
            <i>{{ getTeam.teamCaptain.playerName }}</i>
          </p>
        </div>
      </div>
      <div v-else-if="view === 'ratingMyTeam'" class="dashboard__item">
        <div v-if="currentIsland.name" class="dashboard-text">
          <p>Текущее местоположение</p>
          <p>
            <b>Планета {{ currentIsland.title }}</b>
          </p>
          <div
            class="dashboard-island"
            :class="`dashboard-island_${currentIsland.name}`"
          ></div>
        </div>
        <div v-if="islandCoins && islandCoinsTotal" class="dashboard-text">
          <p>На текущей планете команда заработала GPB-coin</p>
          <p>
            <b>{{ islandCoins }} из {{ islandCoinsTotal }}</b>
          </p>
        </div>
        <div v-if="islandCoins && islandCoinsTotal" class="dashboard-text">
          <p>До перехода на следующую планету осталось</p>
          <p>
            <b>{{ islandCoinsLeft }}</b>
          </p>
        </div>
        <div
          v-if="getTeam.teamCoins !== 'undefinded'"
          class="dashboard-control"
        >
          <p>Количество полученных {{ COINS }} команды</p>
          <div class="score">
            <span>{{ getTeam.teamCoins | priceFilter }}</span>
            <span class="coin">
              <img
                src="@/assets/img/pic2/coin.svg"
                width="13"
                height="13"
                alt=""
              />
            </span>
          </div>
        </div>
        <div
          v-if="getTeam.teamCaptain && getTeam.teamCaptain.playerName"
          class="dashboard-control"
        >
          <p>Капитан команды</p>
          <p>
            <i>{{ getTeam.teamCaptain.playerName }}</i>
          </p>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { islandList } from "@/data/game";

export default {
  name: "Dashboard",
  props: {
    view: String,
  },
  computed: {
    ...mapGetters([
      "getGame",
      "getProfile",
      "getProfileCurrentIsland",
      "getTeam",
    ]),
    currentWeek() {
      let week = this.getGame.currentWeek;
      if (week >= 35) {
        week = 36;
      } else {
        week = week + 1;
      }
      return week;
    },
    islandCoinsLeft() {
      return this.islandCoinsTotal - this.islandCoins;
    },
    currentIsland() {
      const currentIsland = this.getProfileCurrentIsland.islandIdx;
      let data = {};

      if (currentIsland !== undefined) {
        islandList.forEach((island, index) => {
          if (index === currentIsland) {
            data.name = island.name;
            data.title = island.title;
          }
        });
      }

      return data;
    },
  },
  data() {
    return {
      islandCoins: null,
      islandCoinsTotal: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  margin-top: -5px;
  width: 376px;
  height: 600px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='397' height='634' viewBox='0 0 397 634' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.7' d='M12.0352 29.3943c0-11.0457 8.9543-20 20-20H371.806c11.045 0 20 8.9543 20 20V605.652c0 11.046-8.955 20-20 20H32.0352c-11.0457 0-20-8.954-20-20V29.3943Z' fill='%23020711'/%3E%3Cpath d='m1 591.344 4.7517 9.502v12.176l7.8634 16.29H56.225l4.9607-10.277H19.3712l-4.7999-9.943v-8.159L1 573.749v17.595ZM397 39.1053l-5.165-10.4845V16.3787l-7.81-16.3788h-42.32l-4.927 10.3326h41.53l4.767 9.9976v8.2031L397 56.7959V39.1053Z' fill='%235CFFED'/%3E%3Cpath d='M10.5754 601.561v-9.383c0-1.168-.3077-2.317-.8922-3.329a6.6575 6.6575 0 0 1-.892-3.329l.0334-556.2089c.0006-11.0453 8.9548-19.9988 20-19.9988H161.245c3.839 0 7.435 1.8817 9.623 5.0363a11.7098 11.7098 0 0 0 9.622 5.0364h50.531c3.174 0 6.062-1.8305 7.417-4.7001 1.355-2.8697 4.244-4.7002 7.418-4.7002h134.567c6.356 0 11.508 5.1522 11.508 11.5077V605.944c0 11.046-8.954 20-20 20H246.039c-3.362 0-6.426-1.928-7.881-4.959-1.454-3.031-4.518-4.959-7.88-4.959h-51.265c-3.138 0-5.997 1.799-7.354 4.628-1.358 2.828-4.217 4.628-7.355 4.628H32.3609c-7.6924 0-14.7031-4.412-18.0312-11.347l-1.7856-3.721a20.0002 20.0002 0 0 1-1.9687-8.653Z' stroke='%235CFFED' stroke-miterlimit='10'/%3E%3Cpath d='m310.328 633 66.338-.928c10.936-.153 19.721-9.061 19.721-19.998V43.7434M176.279 625.65h54.423M178.73 5.09h54.423M1.0762 500.667v-63.931' stroke='%235CFFED'/%3E%3C/svg%3E");
  background-size: contain;
  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 60px;
  }
  &__title {
    font-size: 38px;
    line-height: 42px;
    color: #ade5d9;
    span {
      opacity: 0.3;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 22px;
    color: #f2f2f2;
    margin-bottom: 15px;
  }
  &__pic {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  &-result {
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &__title {
      font-size: 16px;
      line-height: 22px;
      color: #ade5d9;
      margin-bottom: 15px;
    }
    &__body {
      width: 281px;
      height: 64px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='281' height='64' viewBox='0 0 281 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2300FFFE' fill-opacity='.15' d='M1 0h277v64H1z'/%3E%3Cpath d='M27 2H2v60h25M255 62h24V2h-24' stroke='%2300DFDF' stroke-width='4'/%3E%3C/svg%3E");
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 700;
      font-size: 32px;
      line-height: 37px;
      color: #50ffeb;
      img {
        margin-right: 6px;
        margin-bottom: -6px;
        margin-left: -26px;
      }
    }
  }
  &-island {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    width: 300px;
    height: 210px;
    margin: 0 auto;
    &_asiris {
      background-image: url(~@/assets/img/pic2/previews/asiris.png);
    }
    &_timeless {
      background-image: url(~@/assets/img/pic2/previews/timeless.png);
    }
    &_nibiru {
      background-image: url(~@/assets/img/pic2/previews/nibiru.png);
    }
    &_kriptos {
      background-image: url(~@/assets/img/pic2/previews/kriptos.png);
    }
    &_pandoria {
      background-image: url(~@/assets/img/pic2/previews/pandoria.png);
    }
    &_tatuum {
      background-image: url(~@/assets/img/pic2/previews/tatuum.png);
    }
    &_krediti {
      background-image: url(~@/assets/img/pic2/previews/krediti.png);
    }
    &_giperiya {
      background-image: url(~@/assets/img/pic2/previews/giperiya.png);
    }
    &_morroviya {
      background-image: url(~@/assets/img/pic2/previews/morroviya.png);
    }
  }
}
</style>
